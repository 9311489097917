import User from './User'
import Address from './Address'

export default class Client {

  constructor() {
    this.legal_name = ''
    this.trading_name = ''
    this.registration_number = ''
    this.vat_number = ''
    this.user_id = ''
    this.logo = ''
    this.logo_url = ''
    this.user = new User
    this.address = new Address
  }

}