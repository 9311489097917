import Asset from './Asset'

export default class Barcode
{
    constructor() {
      this.code = ''
      this.data = ''
      this.public = ''
      this.asset_id = ''
      this.asset = {}
    }
}