export default class CustomField {

  constructor() {
    this.custom_fieldable_id = ''
    this.custom_fieldable_type = ''
    this.sort = null
    this.label = ''
    this.placeholder = ''
    this.input_type = ''
    this.value = ''
    this.required = false
    this.predefined_answers = []
  }

}
