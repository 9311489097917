export default class Check
{
    constructor() {
      this.name = ''
      this.instant_fail = false
      this.can_skip = true
      this.task = ''
      this.comment_rule = 'not-required'
      this.image_rule = 'not-required'
      this.locked_checklists_count = 0
        this.image = ''
    }
}
