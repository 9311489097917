export default class Inspection {

  constructor() {
    this.reference = ''
    this.job_id = ''
    this.checklist_id = ''
    this.passed_at = ''
    this.failed_at = ''
    this.asset_id = ''
    this.technician_id = ''
    this.technician = { profile: {} }
    this.answers = []
    this.evidence = []
    this.images = []
    this.comment = ''
    this.comments = ''
    this.image = ''
    this.asset = {
      barcode: {}
    }
    this.job = {
      site: {}
    },
    this.checklist = {}
  }

}
