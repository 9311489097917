import { classes } from '@/mixins/js-helpers'

export class CompanyBranding extends classes.BaseModel {

  constructor(payload) {
    super()
    this.hydrate(payload || {
      app_logo: '',
      email_logo: '',
    })
  }

  get appLogo() {
    if (this.app_logo instanceof File) {
      let formData = new FormData()
      formData.append('app_logo', this.app_logo)
      return formData
    }

    return undefined
  }

  get emailLogo() {
    if (this.email_logo instanceof File) {
      let formData = new FormData()
      formData.append('email_logo', this.email_logo)
      return formData
    }

    return undefined
  }
}
