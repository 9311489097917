export default class Checklist
{
    constructor() {
      this.name = ''
      this.checks = []
      this.asset_types = []
      this.pass_percentage = 80
      this.image_rule = ''
      this.required_image_count = 0
      this.frequency = 0
      this.sets_next_inspection = false
      this.requires_signature = false
      this.requires_approver_signature = false
      this.inspection_report_images_inline = false
      this.use_custom_report = false
    }
}