export default class JobType {

  constructor() {
    this.description = ''
    this.pass_percentage = 100
    this.active = true
    this.requires_signature = false
    this.single_inspection = false
    this.report_with_map = true
    this.report_with_asset_meta = true
    this.job_report_with_asset_meta = true
    this.requires_technician_signature = false
    this.closing_custom_fields = []
  }

}