export default class Address {

  constructor() {
    this.building = ''
    this.street = ''
    this.suburb = ''
    this.city = ''
    this.province = ''
    this.country_id = ''
    this.post_code = ''
  }

}
