import User from './User'
import moment from 'moment'

export default class Job {

  constructor() {
    this.reference = ''
    this.technician_id = ''
    this.client_id = ''
    this.job_type_id = ''
    this.site_id = ''
    this.inspections = []
    this.technician = new User
    this.user = new User
    this.zones = []
    this.statuses = []
    this.type = {}
    this.current_status = {}
    this.site = {}
    this.start_date = ''
    this.start_time = moment().format('HH:mm')
    this.total_minutes = 0
    this.end_date = ''
    this.end_time = moment().endOf('day').format('HH:mm')
    this.allow_early_closure = true
    this.requires_signature = false
    this.answers = []
    this.frequency = {}
    this.documents = []
    this.pauses = []
    this.technician_signature = null
    this.latest_status = ''
  }

}
