export default class AccountEnquiry {

  constructor() {
    this.full_name = ''
    this.email = ''
    this.mobile = ''
    this.dialing_code = ''
    this.company_name = ''
    this.company_size = ''
    this.country_id = ''
    this.industry_id = ''
    this.industry_other = ''
    this.company_bio = ''
  }

}