import AssetType from "./AssetType"
import Barcode from "./Barcode"

export default class Asset
{
    constructor() {
      this.code = ''
      this.next_inspection = ''
      this.image = ''
      this.type = new AssetType
      this.barcode = new Barcode
      this.zone_id = ''
      this.risk_id = ''
      this.barcode_id = ''
      this.location = {}
      this.subAssets = []
      this.type_name = ''
      this.barcode_data = ''
      this.active_dynamic_statuses = []
    }
}