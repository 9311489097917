import DynamicStatusOption from "@/models/DynamicStatusOption";

export default class DynamicStatus
{
    constructor() {
      this.name = ''
      this.description = ''
      this.asset_type_id = null
      this.options = [
          new DynamicStatusOption
      ]
      this.editable = true
    }
}
